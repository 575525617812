import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageHeader = ({ handleLogout }) => {
    const navigate = useNavigate();

    const logout = () => {
        handleLogout();
        navigate('/login');
    };

    return (
        <div className="header" style={{ backgroundColor: "#e9f1fc" }}>
            <div className="container">
                <img
                    src={process.env.PUBLIC_URL + '/logo.png'}
                    alt="Logo"
                    style={{ width: "100px" }}
                />
                <h1>Weekly Release Roadmap</h1>
                <p>Weekly Release roadmap: Our Roadmap for Upcoming Features, Enhancements, and Improvements.</p>
                <button className='logout-button' onClick={logout}>Logout</button>
            </div>
        </div>
    );
};

export default PageHeader;
