import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Column from './components/Column';
import PageHeader from './components/PageHeader';
import Login from './components/Login';

function App() {
  const [data, setData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true');

  const handleLogin = () => {
    setLoggedIn(true);
    localStorage.setItem('loggedIn', 'true');
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('loggedIn');
  };

  useEffect(() => {
    fetch('tasks/task.json')
      .then(response => response.json())
      .then(jsonData => setData(jsonData));
  }, []);

  const MainApp = () => {
    return (
      <>
        <PageHeader handleLogout={handleLogout} />
        <div className="container">
          {data && (
            <>
              <Header title="Release Roadmap" date={`${data.start_date} - ${data.end_date}`} />
              <div className="columns">
                <Column tasks={data.tasks} bucketName="Planned" />
                <Column tasks={data.tasks} bucketName="In_Progress" />
                <Column tasks={data.tasks} bucketName="Done" />
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
        {loggedIn ? (
          <Route path="/" element={<MainApp />} />
        ) : (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
