import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiShow, BiHide } from 'react-icons/bi';
import './Login.css';

const Login = ({ handleLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const submitForm = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const response = await fetch('https://service.edencaremedical.com/prod/api/v1/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    ref_access: 'CAS'
                })
            });

            const data = await response.json();

            if (data.api && data.api.response === 'LOGGED_IN_SUCCESS') {
                handleLogin(true);
                localStorage.setItem("isLoggedIn", true); // Add this line to save login status
                navigate('/');
            } else {
                setError('Incorrect username or password');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
        }

        setLoading(false);
    };

    const handleInputChange = (e) => {
        if (error) {
            setError('');
        }

        if (e.target.name === 'email') {
            setEmail(e.target.value);
        } else if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <form className="form-container" onSubmit={submitForm}>
            <div className="login-container">
                <div className="login-box">
                    <h2>Sign in</h2>
                    <p>Sign in to access Roadmap portal.</p>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        placeholder="Your email"
                        required
                    />
                    <div className="password-input">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            placeholder="Your password"
                            required
                            className="password-input-field"
                        />

                        <button type="button" className="password-toggle" onClick={toggleShowPassword}>
                            {showPassword ? <BiHide /> : <BiShow />}
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <input
                        type="submit"
                        value={loading ? 'Loading...' : 'Login'}
                        disabled={loading}
                    />
                </div>
            </div>
        </form>
    );
};

export default Login;
