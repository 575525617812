import React from 'react';

const Header = ({ title, date }) => (
    <div className="content-header">
        <h1 className="header-title">{title}</h1>
        <p className="header-date">{date}</p>
    </div>
);

export default Header;
