import React from 'react';
import plannedIcon from '../assets/plan.svg';
import inProgressIcon from '../assets/progress.svg';
import doneIcon from '../assets/done.svg';

const Column = ({ tasks, bucketName }) => {
    const bucketTasks = tasks.filter(task => task.bucket === bucketName);

    const getIcon = (bucket) => {
        switch (bucket) {
            case "Planned":
                return plannedIcon;
            case "In_Progress":
                return inProgressIcon;
            case "Done":
                return doneIcon;
            default:
                return plannedIcon; // default icon
        }
    }

    const formattedBucketName = bucketName.replace(/_/g, " ");

    return (
        <div className="column">
            <div className="column-heading">{formattedBucketName} - {bucketTasks.length} tasks</div>
            {bucketTasks.map((task, index) => (
                <div className="task" key={index}>
                    <div className="task-header">
                        <img src={getIcon(bucketName)} alt="task icon" className="task-icon" />
                        <div>
                            <h2 className="task-title">{task.title}</h2>
                            <p className="task-desc">{task.description}</p>
                            <div className="tag-container">
                                {task.tags.map((tag, i) => (
                                    <span className="tag" key={i}>{tag}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Column;
